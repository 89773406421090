import React, { MouseEventHandler } from 'react';
import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { Category } from '../../types/audi-footer-response.types';
import Links from '../links/links';
import { breakpointM } from '../footer/audi-feature-app-footer';

type CategoriesProps = {
  categories?: Category[];
  useOneLayer?: boolean;
  openLayer: MouseEventHandler;
};

export const timeL = '240ms';

const FooterSitemapStyled = styled.div`
  margin: var(--one-footer-space-xxl) 0 0;

  & > ul {
    display: block;
    list-style: none;
    margin: 0 0 var(--one-footer-space-xxl) calc(-1 * var(--one-footer-side-spacing));
    /* stylelint-disable */
    ${(t: ThemeProps): string =>
      responsiveStyles(
        {
          display: { m: 'flex' },
          'flex-flow': { m: 'row wrap' },
          margin: { m: 'var(--one-footer-space-xxl) auto 0' },
          width: { m: '100%' },
        },
        t.theme.breakpoints,
      )}
    /* stylelint-enable */
    padding: 0;
    width: calc(100% + 2 * var(--one-footer-side-spacing));

    & > li {
      margin-bottom: -1px;
      ${(t: ThemeProps): string =>
        responsiveStyles(
          {
            margin: {
              m: '0 var(--one-footer-space-xl) var(--one-footer-space-xxl) 0',
            },
            padding: { m: '0' },
            width: {
              m: 'calc(33% - var(--one-footer-space-xl) * 2 / 3)',
              xl: 'calc(20% - var(--one-footer-space-xl) * 4 / 5)',
            },
          },
          t.theme.breakpoints,
        )}

      &:nth-of-type(3n) {
        ${(t: ThemeProps): string =>
          responsiveStyles(
            {
              'margin-right': {
                m: '0',
                xl: 'var(--one-footer-space-xl)',
              },
            },
            t.theme.breakpoints,
          )}
      }

      &:nth-of-type(5n) {
        ${(t: ThemeProps): string =>
          responsiveStyles(
            {
              'margin-right': {
                xl: '0',
              },
            },
            t.theme.breakpoints,
          )}
      }

      &:first-of-type {
        /* stylelint-disable */
        margin-top: calc(var(--one-footer-space-xxxl) - var(--one-footer-space-l));
        /* stylelint-enable */
        ${(t: ThemeProps): string =>
          responsiveStyles(
            {
              'margin-top': { m: '0' },
            },
            t.theme.breakpoints,
          )}
      }

      &:last-of-type {
        /* stylelint-disable */
        margin-bottom: calc(var(--one-footer-space-xxl) - var(--one-footer-space-l));
        /* stylelint-enable */
        ${(t: ThemeProps): string =>
          responsiveStyles(
            {
              'margin-bottom': { m: 'var(--one-footer-space-xxl)' },
            },
            t.theme.breakpoints,
          )}
      }
    }
  }
`;

const FooterSitemapCategoryLinkWrapperStyled = styled.div`
  align-content: center;
  border-bottom: 1px solid var(--one-footer-neutral-10);
  border-top: 1px solid var(--one-footer-neutral-10);
  cursor: pointer;
  padding: 0 var(--one-footer-side-spacing);
  position: relative;
  ${(t: ThemeProps): string =>
    responsiveStyles(
      {
        border: { m: 'none' },
        cursor: { m: 'initial' },
        'margin-bottom': { m: 'var(--one-footer-space-xl)' },
        padding: { m: '0' },
      },
      t.theme.breakpoints,
    )}
  width: 100%;
  z-index: 2;

  & > a {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: var(--one-footer-space-m) 0;
    ${(t: ThemeProps): string =>
      responsiveStyles(
        {
          padding: { m: '0' },
          width: { m: 'auto' },
        },
        t.theme.breakpoints,
      )}
    width: 100%;

    & > span {
      ${(t: ThemeProps): string => `
        font-family: var(${t.theme.responsive.typography.copy1.fontFamily});
        font-size: var(${t.theme.responsive.typography.copy1.fontSize});
        font-stretch: var(${t.theme.responsive.typography.copy1.fontStretch});
        line-height: var(${t.theme.responsive.typography.copy1.lineHeight});
        ${responsiveStyles(
          {
            'font-family': {
              m: `var(${t.theme.responsive.typography.order4.fontFamily})`,
            },
            'font-size': {
              m: `var(${t.theme.responsive.typography.order4.fontSize})`,
            },
            'font-stretch': {
              m: `var(${t.theme.responsive.typography.order4.fontStretch})`,
            },
            'line-height': {
              m: `var(${t.theme.responsive.typography.order4.lineHeight})`,
            },
          },
          t.theme.breakpoints,
        )}`}
    }

    & > svg {
      flex: none; /* prevent arrow shrink on long labels */
      margin: 0 0 0 var(--one-footer-space-l);
      transition: transform ${timeL} var(${(t: ThemeProps): string => t.theme.easing});
      ${(t: ThemeProps): string =>
        responsiveStyles(
          {
            display: { m: 'none' },
          },
          t.theme.breakpoints,
        )}
    }
  }

  &[data-submenu-open*='true'] > a > svg {
    transform: rotate(-180deg);
  }

  &[data-submenu-open*='true'] + div {
    background-color: var(--one-footer-neutral-5);
    height: auto;
    max-height: 2000px;
    opacity: 1;
    ${(t: ThemeProps): string =>
      responsiveStyles(
        {
          'background-color': { m: 'var(--one-footer-color-black)' },
        },
        t.theme.breakpoints,
      )}
  }
`;

const Categories: React.FC<CategoriesProps> = ({ categories, useOneLayer, openLayer }) => {
  function onClick(event: React.MouseEvent): void {
    if (window && window.innerWidth < breakpointM) {
      event.preventDefault();
      const isOpen =
        event.currentTarget.getAttribute('data-submenu-open') === 'true' ? 'false' : 'true';
      event.currentTarget.setAttribute('data-submenu-open', isOpen);
    }
  }

  return (
    <FooterSitemapStyled>
      <ul>
        {categories?.map(({ Link, SubLinks }) => {
          const randomNumber = Math.floor(Math.random() * 1000) + 1;

          return (
            <li key={`${randomNumber}${Link.Target}${Link.Text}${Link.Url}`}>
              <FooterSitemapCategoryLinkWrapperStyled
                {...(!!SubLinks && !!SubLinks.length && SubLinks.length > 0 ? { onClick } : {})}
                {...(!!SubLinks && !!SubLinks.length && SubLinks.length > 0
                  ? { 'data-submenu-open': false }
                  : {})}
              >
                <a href={Link.Url} target={Link.Target}>
                  <span>{Link.Text}</span>
                  {!!SubLinks && !!SubLinks.length && SubLinks.length > 0 && (
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10l5.5 5.5L18 10" fill="none" stroke="currentColor" />
                    </svg>
                  )}
                </a>
              </FooterSitemapCategoryLinkWrapperStyled>
              {/* eslint-disable-next-line max-len */}
              {!!SubLinks && !!SubLinks.length && SubLinks.length > 0 && (
                <Links links={SubLinks} useOneLayer={useOneLayer} openLayer={openLayer} />
              )}
            </li>
          );
        })}
      </ul>
    </FooterSitemapStyled>
  );
};

export default Categories;
