import React, { MouseEventHandler } from 'react';
import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';
import {
  getClassNamesForLinks,
  getIdNameForLink,
  getLinkTarget,
  onCookieModalLinkClick,
} from '../../services/helpers';
import { Link } from '../../types/audi-footer-response.types';

type LinksProps = {
  countryCode?: string;
  links: Link[];
  useOneLayer?: boolean;
  openLayer: MouseEventHandler;
};

const FooterLinksWrapperStyled = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  ${(t: ThemeProps): string =>
    responsiveStyles(
      {
        'background-color': { m: 'var(--one-footer-color-black)' },
        height: { m: 'auto' },
        margin: { m: '0' },
        'max-height': { m: 'initial' },
        opacity: { m: '1' },
      },
      t.theme.breakpoints,
    )}
  transition: all 240ms var(${(t: ThemeProps): string => t.theme.easing});

  & > ul {
    padding: calc(var(--one-footer-space-l) - var(--one-footer-space-m) / 2) 0;
    /* stylelint-disable */
    ${(t: ThemeProps): string =>
      responsiveStyles(
        {
          padding: { m: '0' },
        },
        t.theme.breakpoints,
      )}

    & > li {
      padding: 0 var(--one-footer-side-spacing);
      ${(t: ThemeProps): string =>
        responsiveStyles(
          {
            padding: { m: '0 0 var(--one-footer-space-s)' },
          },
          t.theme.breakpoints,
        )}
      /* stylelint-enable */
      & > a {
        display: block;
        padding: calc(var(--one-footer-space-m) / 2) 0;
        width: 100%;
        ${(t: ThemeProps): string =>
          responsiveStyles(
            {
              display: { m: 'inline' },
              padding: { m: '0' },
              width: { m: 'auto' },
            },
            t.theme.breakpoints,
          )}
      }
    }

    & > li:last-of-type {
      ${(t: ThemeProps): string =>
        responsiveStyles(
          {
            padding: { m: '0' },
          },
          t.theme.breakpoints,
        )}
    }
  }
`;

const Links: React.FC<LinksProps> = ({ countryCode, links, useOneLayer, openLayer }) => {
  return (
    <FooterLinksWrapperStyled>
      <ul>
        {links.map((link) => {
          const randomNumber = Math.floor(Math.random() * 1000) + 1;
          return (
            <li
              /* nemo specific cookie modal link click */
              /* eslint-disable-next-line no-nested-ternary */
              {...(!!link.Type && link.Type === 'cookie-settings-layer' && countryCode !== 'US'
                ? { onClick: onCookieModalLinkClick }
                : link.Target === '_layer' && useOneLayer
                  ? { onClick: openLayer }
                  : {})}
              data-is-overview-link={link.IsOverview}
              key={`${randomNumber}${link.Target}${link.Text}${link.Url}`}
            >
              <a
                className={getClassNamesForLinks(link, countryCode, useOneLayer)}
                href={link.Url}
                target={getLinkTarget(link, useOneLayer)}
                {...(getIdNameForLink(link) ? { id: getIdNameForLink(link) } : {})}
              >
                {link.Text}
              </a>
            </li>
          );
        })}
      </ul>
    </FooterLinksWrapperStyled>
  );
};

export default Links;
