import React from 'react';
import { ButtonGroup, Button, responsiveStyles } from '@audi/audi-ui-react';
import { IconNamesMerged } from '@audi/audi-ui-react/dist/cjs/components/Icon/Icon.types.mjs.cjs';
import styled, { AnyStyledComponent } from 'styled-components';
import { SocialMediaType } from '../../types/audi-footer-response.types';

interface SocialMediaProps {
  socialMedia: SocialMediaType[];
}

const iconNameMapping: { [key: string]: IconNamesMerged } = {
  twitter: 'x',
  line: 'line-me',
};

const RightAlignedButtonGroup = styled(ButtonGroup as AnyStyledComponent)<{ variant: string }>`
  & > div {
    // custom css in order to align items in <ButtonGroup> right
    ${(t: ThemeProps): string =>
      responsiveStyles(
        {
          'justify-content': { xxs: 'flex-start', m: 'flex-end' },
        },
        t.theme.breakpoints,
      )}
    display: flex;
  }
`;

const SocialMedia: React.FC<SocialMediaProps> = ({ socialMedia }) => {
  return (
    <RightAlignedButtonGroup variant="block-buttons">
      {socialMedia.map((socialMediaItem) => {
        const { Id, Link } = socialMediaItem;
        return (
          <Button
            href={Link.Url}
            target={Link.Target}
            key={Id}
            variant="icon-primary"
            icon={iconNameMapping[Id] || Id}
          />
        );
      })}
    </RightAlignedButtonGroup>
  );
};

export default SocialMedia;
